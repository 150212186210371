import React from "react";
import VerticalNav from "../Components/VerticalNav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../media/logo.png";

const Home = () => {
  return (
    <>
      <Container fluid>
        <Row style={{ width: "100vw" }}>{/* <HeaderBar logo={logo} /> */}</Row>
        <Row style={{ height: "100vh" }}>
          <Col sm={2} style={{ backgroundColor: "#1e1f1c" }}>
            <VerticalNav
              logo={logo}
              link={[
                { ref: "/", name: "Home" },
                { ref: "/Binds", name: "Binds" },
                { ref: "/About", name: "About" },
              ]}
              active={"/"}
            />
          </Col>
          <Col sm={10} style={{ backgroundColor: "#272822" }}>
            <div
              style={{
                width: "80vw",
                height: "96vh",
                backgroundColor: "#3e3d32",
                padding: "20px",
                margin: "30px 20px",
                borderRadius: "25px",
              }}
            >
              <h1 className="cardTextTitle">Homepage</h1>
              <p className="cardTextBody">Just some stuff, websitised.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
