import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

function VerticalNav({ logo, link, active }) {
  return (
    <>
      <img src={logo} alt="img" className="center" style={{marginTop: '30px'}}></img>
      <Nav
        variant="pills"
        defaultActiveKey={active}
        className="flex-column"
        justify
      >
        {link.map((lin, index) => (
          // <Nav.Item>
          //   <Nav.Link as={Link} to={lin.ref}>
          //     {lin.name}
          //   </Nav.Link>
          // </Nav.Item>
          <Nav.Item key={index}>
          <Nav.Link href={lin.ref}>
            {lin.name}
          </Nav.Link>
        </Nav.Item>
        ))}
      </Nav>
    </>
  );
}

export default VerticalNav;
