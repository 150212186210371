import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './screens/home.js';
import Binds from './screens/binds.js';
import Misc from './screens/misc.js';
import About from './screens/about.js';

export default function App() {
  return (
    <Router>
        <Routes>
          <Route path="/Binds" element={<Binds />}  />
          <Route exact path="/" element={<Home />}  />
          <Route exact path="/About" element={<About />}  />
          {/* <Route exact path="/Misc" element={<Misc />}  /> */}
        </Routes>
    </Router>
  );
}

