import React from "react";
import VerticalNav from "../Components/VerticalNav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../media/logo.png";
import HeaderBar from "../Components/HeaderBar";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import BindForm from "../Components/BindForm";

const Binds = () => {
  return (
    <>
      <Container fluid>
        <Row style={{ width: "100vw" }}>{/* <HeaderBar logo={logo} /> */}</Row>
        <Row style={{ height: "100vh" }}>
          <Col sm={2} style={{ backgroundColor: "#1e1f1c" }}>
            <VerticalNav
              logo={logo}
              link={[
                { ref: "/", name: "Home" },
                { ref: "/Binds", name: "Binds" },
                { ref: "/About", name: "About" },
              ]}
              active={"/Binds"}
            />
          </Col>
          <Col sm={10} style={{ backgroundColor: "#272822" }}>
            <div
              style={{
                width: "80vw",
                height: "96vh",
                backgroundColor: "#3e3d32",
                padding: "20px",
                margin: "30px 20px",
                borderRadius: "25px",
              }}
            >
              <h1 className="cardTextTitle">CS:GO Bind/Alias Creator</h1>
              <p className="cardTextBody">
                Select a key by clicking the 'Keybind' field and pressing the
                desired key, then paste your text into the 'text for bind' field
                and click the 'Create Bind' button.
              </p>
              <BindForm />
            </div>
            {/* <Card style={{ width: "80vw", height: '95vh', backgroundColor: '#3e3d32', padding: '20px', margin: '30px 20px'}}>
              {/* <Card.Img variant="top" src="holder.js/100px180" />
              <Card.Body>
                <Card.Title className="cardText">Homepage</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Binds;
