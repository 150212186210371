import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const BindForm = () => {
  const [text, setText] = useState("");
  const [bindName, setBindName] = useState("");
  const [key, setKey] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [substrings, setSubstrings] = useState([]);
  const keyInputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // setKey(z);
    const splitText = text
      .split(".")
      .filter((substring) => substring.trim() !== "");
    const modifiedText = splitText.map(
      (substring, index) =>
        `alias "n${index}" \"say ${substring}; alias super2 n${index + 1}\"\n`
    );
    modifiedText[modifiedText.length - 1] = `alias "n${modifiedText.length - 1}" \"say ${splitText[modifiedText.length - 1]}; alias super2 n0\"\n`;
    modifiedText.unshift('alias "super2" "n0"\n');
    modifiedText.push(`bind "${key}" "super2"`);
    setSubstrings(modifiedText);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSubstrings([]);
    setText("");
    setKey("");
    setBindName("");
  };

  const generateCfgFile = () => {
    let cfgContent = '';
    let cfgTitle = bindName;
    const splitText = text.split('.').filter(substring => substring.trim() !== '');
    cfgContent += 'alias "super2" "n0"\n';

    splitText.forEach((substring, index) => {
      if (index !== splitText.length - 1) {
        cfgContent += `alias "n${index}" \"say ${substring}; alias super2 n${(index + 1) % splitText.length}\"\n`;
      } else {
        cfgContent += `alias "n${index}" say ${substring}; alias super2 n0`;
      }
    });

    cfgContent += `\nbind "${key}" "super2"`;
    

    const element = document.createElement('a');
    const file = new Blob([cfgContent], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = bindName+'.cfg';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleKeyDown = (e) => {
    if (keyInputRef.current === document.activeElement) {
      setKey(e.key);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Form onSubmit={handleSubmit}>
      <Form.Group controlId="text">
          <Form.Label className="cardTextBody">Name of bind</Form.Label>
          <Form.Control
            type="text"
            rows={3}
            value={bindName}
            required
            onChange={(e) => setBindName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="key">
          <Form.Label className="cardTextBody">Keybind</Form.Label>
          <Form.Control
            type="text"
            value={key}
            ref={keyInputRef}
            readOnly
            required
            onFocus={() => setKey("")}
          />
        </Form.Group>
        <Form.Group controlId="text">
          <Form.Label className="cardTextBody">Text for bind</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={text}
            required
            onChange={(e) => setText(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" style={{ margin: "15px 0px" }}>
          Create Bind
        </Button>
      </Form>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Split Text Result</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{whiteSpace: 'pre-line'}}>
          {substrings}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={generateCfgFile}>
            Download as .cfg
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BindForm;
